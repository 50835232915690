var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-2"},[_vm._v(" Choose the correct description for the various points on the phase change diagram. (G and F should be answered as if energy is increasing.) ")]),_c('p',{staticClass:"mb-5"},[_c('v-img',{staticStyle:{"max-width":"400px"},attrs:{"src":"/img/assignments/energyAddedGraph.png"}})],1),_c('p',{staticClass:"mb-2"},[_c('v-select',{staticStyle:{"max-width":"300px"},attrs:{"items":_vm.items1Shuffled,"item-text":"text","item-value":"value","label":"A"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}})],1),_c('p',{staticClass:"mb-2"},[_c('v-select',{staticStyle:{"max-width":"300px"},attrs:{"items":_vm.items1Shuffled,"item-text":"text","item-value":"value","label":"B"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1),_c('p',{staticClass:"mb-2"},[_c('v-select',{staticStyle:{"max-width":"300px"},attrs:{"items":_vm.items1Shuffled,"item-text":"text","item-value":"value","label":"C"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}})],1),_c('p',{staticClass:"mb-2"},[_c('v-select',{staticStyle:{"max-width":"300px"},attrs:{"items":_vm.items1Shuffled,"item-text":"text","item-value":"value","label":"D"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}})],1),_c('p',{staticClass:"mb-2"},[_c('v-select',{staticStyle:{"max-width":"300px"},attrs:{"items":_vm.items1Shuffled,"item-text":"text","item-value":"value","label":"E"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input5),callback:function ($$v) {_vm.$set(_vm.inputs, "input5", $$v)},expression:"inputs.input5"}})],1),_c('p',{staticClass:"mb-2"},[_c('v-select',{staticStyle:{"max-width":"300px"},attrs:{"items":_vm.items2Shuffled,"item-text":"text","item-value":"value","label":"F"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input6),callback:function ($$v) {_vm.$set(_vm.inputs, "input6", $$v)},expression:"inputs.input6"}})],1),_c('p',{staticClass:"mb-2"},[_c('v-select',{staticStyle:{"max-width":"300px"},attrs:{"items":_vm.items2Shuffled,"item-text":"text","item-value":"value","label":"G"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input7),callback:function ($$v) {_vm.$set(_vm.inputs, "input7", $$v)},expression:"inputs.input7"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }